
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import {Action, Getter} from "vuex-class";
    import {IUserDB, StoreAction} from "@/types";
    import {showModal} from "@/helpers/callables";
    import EditCollaborator from "@/components/modal/EditCollaborator.vue";
    import {capitalize} from "@/helpers/commons";

    @Component({
        name: "Collaborators",
        components: {EditCollaborator}
    })
    export default class Collaborators extends Vue {
        @Action('actions/listCollaborators') listCollaborators!: StoreAction;
        @Action('actions/createCollaborator') createCollaborator!: StoreAction;
        @Action('actions/editCollaborator') editCollaborator!: StoreAction;
        @Action('actions/deleteCollaborator') deleteCollaborator!: StoreAction;
        @Action('actions/getGlobalConfiguration') getGlobalConfiguration!: StoreAction;

        @Getter('getJob') getJob!: any;
        @Getter('isCompany') isCompany!: boolean;
        @Getter('isAgency') isAgency!: boolean;
        @Getter('isManager') isManager!: boolean;
        @Getter('getJobOption') getJobOption!: any;

        collaborators: IUserDB[] = [];
        collaboratorToEdit: any = null;
        collaboratorToRemove: IUserDB | null = null;
        newCollaborator: any = null;

        errors: any = {};
        globalErrors: any = [];

        loading = false;
        innerLoading = false;
        showConfirmRemove = false;
        showEditCollaborator = false;

        add = false;

        get headers() {
            return [
                {
                    field: "_id",
                    hidden: true,
                    sortable: false
                },
                {
                    field: 'lastname',
                    label: this.$t('form.lastname'),
                    sortable: false,
                    formatFn: (value:string) => capitalize(value)
                },
                {
                    field: 'firstname',
                    label: this.$t('form.firstname'),
                    sortable: false,
                    formatFn: (value:string) => capitalize(value)
                },
                {
                    field: 'username',
                    label: this.$t('form.username'),
                    sortable: false,
                    formatFn: (value:string) => capitalize(value)
                },
                {
                    field: 'email',
                    label: this.$t('form.email'),
                    sortable: false
                },
                {
                    field: 'phone',
                    label: this.$t('form.phone'),
                    sortable: false
                },
                {
                    field: 'position',
                    label: this.$t('form.post'),
                    formatFn: capitalize,
                    sortable: false
                },
                {
                    field: 'role',
                    label: this.$t('form.role'),
                    formatFn: (value:string) => this.$t('roles.' + value),
                    sortable: false
                },
                {
                    field: '__actions',
                    sortable: false
                }

            ];
        }

        get rows() {
            return this.collaborators;
        }

        get collabRemoveName() {
            const c: IUserDB | null = this.collaboratorToRemove;

            if (c) {
                return `${c.firstname} ${c.lastname}`;
            }
            return "";
        }

        resetNew() {
            this.newCollaborator = {
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
                role: this.isAgency ? "collaborator" : "company",
                position: "",
            }
        }

        openRemove(c: IUserDB) {
            this.collaboratorToRemove = c;
            showModal.call(this, 'ConfirmRemove');
        }

        openEdit(c: IUserDB) {
            this.errors = {};
            this.globalErrors = [];
            this.collaboratorToEdit = c;
            showModal.call(this, 'EditCollaborator');
        }

        openAdd() {
            this.errors = {};
            this.globalErrors = [];
            this.add = true;
            this.resetNew();
            this.collaboratorToEdit = this.newCollaborator;
            showModal.call(this, 'EditCollaborator');
        }

        validate()  {
            if (!this.collaboratorToEdit?.firstname) {
                this.errors.firstname = {id: "Validation.Any.Base"};
            }
            if (!this.collaboratorToEdit?.lastname) {
                this.errors.lastname = {id: "Validation.Any.Base"};
            }
            if (!this.collaboratorToEdit?.email && !this.collaboratorToEdit?.phone) {
                this.globalErrors.push({id: "user.defineEmailOrPhone"});
            }
            if (!this.collaboratorToEdit?.role) {
                this.errors.role = {id: "Validation.Any.Base"};
            }

            return !Object.keys(this.errors).length && !this.globalErrors.length;
        }

        async submitDelete() {
            this.innerLoading = true;
            this.errors = {};
            this.globalErrors = [];
            try {
                await this.deleteCollaborator(this.collaboratorToRemove);
                this.collaborators = this.collaborators.filter((c: IUserDB) => c._id !== this.collaboratorToRemove?._id);
                this.$modal.hide('ConfirmRemove');
                this.collaboratorToRemove = null;
            } catch (e) {
                console.log(e);
                this.errors = e.errors;
                this.globalErrors = e._global;
            }
            this.innerLoading = false;
        }

        async submitEdit() {
            this.innerLoading = true;
            this.errors = {};
            this.globalErrors = [];
            try {
                if (this.validate()) {
                    if(this.add) {
                        this.collaboratorToEdit = await this.createCollaborator(this.collaboratorToEdit);
                    } else {
                        this.collaboratorToEdit = await this.editCollaborator(this.collaboratorToEdit);
                    }
                    this.add = false;
                    this.$modal.hide('EditCollaborator');
                    this.collaboratorToEdit = null;
                    this.resetNew();
                    await this.init();
                }
            } catch (e) {
                console.log(e);
                this.errors = e.errors;
                this.globalErrors = e._global;
            }
            this.innerLoading = false;
        }

        async init() {
            this.loading = true;
            try {
                this.collaborators = await this.listCollaborators();
            } catch (e) {
                console.log(e);
            }
            this.loading = false;
        }

        beforeMount() {
            if (this.isAgency && !this.isManager) {
                this.$router.push("/");
            }
            this.init();
        }
    }
