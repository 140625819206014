
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import ModalMixin from "@/components/modal/ModalMixin.vue";
    import CollaboratorForm from "@/components/collaborators/CollaboratorForm.vue";
    import {IUserDB} from "@/types";

    @Component({
        name: "EditCollaborator",
        components: {CollaboratorForm}
    })
    export default class EditCollaborator extends mixins(ModalMixin) {
        name = "EditCollaborator";

        @Prop() collaborator!: IUserDB;
        @Prop() errors!: any;
        @Prop() globalErrors!: any;
    }
