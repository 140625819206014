
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import VModel from "@/mixins/VModel.vue";
    import {Getter} from "vuex-class";
    import {LabelValue} from "@/types";
    import ListAutocompleteSelect from "@/components/ListAutocompleteSelect.vue";

    @Component({
        name: "CollaboratorForm",
        components: {ListAutocompleteSelect}
    })
    export default class CollaboratorForm extends mixins(VModel) {
        @Getter('agencyRoleOptions') agencyRoleOptions!:LabelValue[];
        @Getter('companyRoleOptions') companyRoleOptions!:LabelValue[];
        @Prop() errors!: any;
        @Prop() globalErrors!: any;
        @Getter('jobOptions') jobOptions!: LabelValue[];
        @Getter('isAgency') isAgency!: boolean;

        copied = false;

        get username():string {
            this.innerValue.username = ((this.innerValue.firstname).trim() + "." + (this.innerValue.lastname).trim()).toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

            return this.innerValue.username;
        }

        blur() {
            // if (!this.copied) {
            //     if (!this.innerValue.username) {
            //         this.innerValue.username = (this.innerValue.firstname + "." + this.innerValue.lastname).toLowerCase();
            //     }
            //     this.copied = true;
            // }
        }
    }
